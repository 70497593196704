<header>
  <div class="header-navigation">
    <div class="container-fluid">
      <a
        class="navbar-brand logo-container"
        href="//www.koodoo.pl">
        <img
          class="logo"
          src="../../../assets/logo-text.png"
          alt="Koodoo logo with text">
      </a>
    </div>
    <div class="container-fluid">
    </div>
  </div>

  <a [routerLink]="'/'" class="gift">TEST DISC</a>
</header>
