<div class="col-lg-4 col-md-6 col-xs-12">
  <div
    [ngClass]="question.isSelected ? '' : 'question-box-not-selected'"
    class="list-group-item clearfix question-box">
    <app-test-row
      *ngFor="let answer of question.answers; let i = index"
      [answer]="answer"
      [index]="i"
      class="row"
      (answerSelected)="onRowClick($event)">
    </app-test-row>
  </div>
</div>
