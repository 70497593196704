import { TestQuestion } from '../containers/disc-test/disc-test.model';

export const questions: TestQuestion[] = [
  {
    answers: [
      {
        answer: 'test.question01.answer1',
        points: [2, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question01.answer2',
        points: [4, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question01.answer3',
        points: [5, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question01.answer4',
        points: [3, 3],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question02.answer1',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question02.answer2',
        points: [4, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question02.answer3',
        points: [5, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question02.answer4',
        points: [3, 3],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question03.answer1',
        points: [5, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question03.answer2',
        points: [1, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question03.answer3',
        points: [5, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question03.answer4',
        points: [2, 2],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question04.answer1',
        points: [4, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question04.answer2',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question04.answer3',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question04.answer4',
        points: [5, 2],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question05.answer1',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question05.answer2',
        points: [2, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question05.answer3',
        points: [5, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question05.answer4',
        points: [1, 1],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question06.answer1',
        points: [4, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question06.answer2',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question06.answer3',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question06.answer4',
        points: [2, 2],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question07.answer1',
        points: [5, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question07.answer2',
        points: [3, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question07.answer3',
        points: [5, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question07.answer4',
        points: [2, 2],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question08.answer1',
        points: [4, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question08.answer2',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question08.answer3',
        points: [2, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question08.answer4',
        points: [1, 1],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question09.answer1',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question09.answer2',
        points: [5, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question09.answer3',
        points: [5, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question09.answer4',
        points: [4, 4],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question10.answer1',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question10.answer2',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question10.answer3',
        points: [2, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question10.answer4',
        points: [5, 4],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question11.answer1',
        points: [4, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question11.answer2',
        points: [4, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question11.answer3',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question11.answer4',
        points: [5, 3],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question12.answer1',
        points: [2, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question12.answer2',
        points: [5, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question12.answer3',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question12.answer4',
        points: [3, 5],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question13.answer1',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question13.answer2',
        points: [4, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question13.answer3',
        points: [2, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question13.answer4',
        points: [3, 5],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question14.answer1',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question14.answer2',
        points: [4, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question14.answer3',
        points: [2, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question14.answer4',
        points: [3, 5],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question15.answer1',
        points: [4, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question15.answer2',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question15.answer3',
        points: [2, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question15.answer4',
        points: [3, 3],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question16.answer1',
        points: [2, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question16.answer2',
        points: [5, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question16.answer3',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question16.answer4',
        points: [4, 5],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question17.answer1',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question17.answer2',
        points: [2, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question17.answer3',
        points: [5, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question17.answer4',
        points: [3, 3],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question18.answer1',
        points: [4, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question18.answer2',
        points: [5, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question18.answer3',
        points: [5, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question18.answer4',
        points: [1, 1],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question19.answer1',
        points: [2, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question19.answer2',
        points: [3, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question19.answer3',
        points: [5, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question19.answer4',
        points: [1, 1],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question20.answer1',
        points: [4, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question20.answer2',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question20.answer3',
        points: [1, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question20.answer4',
        points: [2, 2],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question21.answer1',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question21.answer2',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question21.answer3',
        points: [5, 4],
        isSelected: [false, false],
      },
      {
        answer: 'test.question21.answer4',
        points: [2, 2],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question22.answer1',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question22.answer2',
        points: [5, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question22.answer3',
        points: [1, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question22.answer4',
        points: [4, 4],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question23.answer1',
        points: [5, 1],
        isSelected: [false, false],
      },
      {
        answer: 'test.question23.answer2',
        points: [2, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question23.answer3',
        points: [3, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question23.answer4',
        points: [5, 4],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
  {
    answers: [
      {
        answer: 'test.question24.answer1',
        points: [2, 2],
        isSelected: [false, false],
      },
      {
        answer: 'test.question24.answer2',
        points: [5, 3],
        isSelected: [false, false],
      },
      {
        answer: 'test.question24.answer3',
        points: [1, 5],
        isSelected: [false, false],
      },
      {
        answer: 'test.question24.answer4',
        points: [4, 5],
        isSelected: [false, false],
      },
    ],
    isSelected: false,
  },
];
