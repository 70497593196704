import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TestQuestion } from './disc-test.model';
import { TestService } from '../../services/test.service';
import { CodeService } from '../../services/code.service';

@Component({
  selector: 'app-disc-test',
  templateUrl: './disc-test.component.html',
  styleUrls: ['./disc-test.component.css'],
})
export class DiscTestComponent implements OnInit {
  questions: TestQuestion[];
  isError = false;
  code: string = null;

  constructor(
    public readonly testService: TestService,
    private readonly codeService: CodeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const code = this.codeService.getCode();
    if (!code) {
      this.router.navigate([''], { queryParams: { code: 'missing_code' }, skipLocationChange: true }).then();
    } else {
      this.codeService
        .isCodeValid(this.codeService.getCode())
        .subscribe(isCodeValid => {
          if (!isCodeValid) {
            return this.router.navigate([''], { queryParams: { code: 'wrong_code' }, skipLocationChange: true }).then();
          }

          this.questions = this.testService.getTestQuestions();
        });
    }
  }

  onSubmit(): void {
    if (!this.testService.isAnswerValid()) {
      this.isError = true;
    } else {
      this.isError = false;
      this.testService.countAnswers().subscribe(results => {
        this.testService.saveAnswers(results);

        this.router.navigate(['wyniki'], { relativeTo: this.route }).then();
      });
    }
  }
}
