import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestService } from '../../services/test.service';
import { Router } from '@angular/router';
import { CodeService } from '../../services/code.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { InputDialogComponent } from '../../shared/input-dialog/input-dialog.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-test-results-with-code',
  templateUrl: './test-results-with-code.component.html',
  styleUrls: ['./test-results-with-code.component.scss'],
})
export class TestResultsWithCodeComponent implements OnInit, OnDestroy {
  answersMost: { name: string, value: number }[];
  answersLeast: { name: string, value: number }[];
  mappedAnswers: { name: string, value: number }[];
  view = [700, 400];
  personalityType: string[];
  monsters: { url: string, class?: string }[] = [];

  code: string = null;

  downloadPdfLoading = false;
  sendPdfLoading = false;

  colorScheme = {
    domain: ['#d4554f', '#f9ba5a', '#5ab88f', '#1f5ba4'],
  };

  constructor(
    public testService: TestService,
    private readonly notificationService: NotificationService,
    private dialog: MatDialog,
    private readonly t: TranslateService,
    private readonly codeService: CodeService,
    private readonly router: Router) {
  }

  ngOnInit(): void {
    this.codeService
      .isCodeValid(this.codeService.getCode())
      .subscribe(isCodeValid => {
        if (!isCodeValid) {
          return this.router.navigate([''], {
            queryParams: { code: 'wrong_code' }, skipLocationChange: true,
          }).then();
        }
        const answers = JSON.parse(this.testService.getAnswers());
        if (answers) {
          this.prepareAnswers(answers);
        } else {
          return this.router.navigate([''], {
            queryParams: { code: 'answers_missing' }, skipLocationChange: true,
          }).then();
        }
      });
  }

  ngOnDestroy() {
    this.codeService.removeCode();
  }

  onResize() {
    this.setView();
  }

  prepareAnswers(answers): void {
    this.setView();
    this.answersLeast = answers.least.map(item => {
      const name = this.t.instant('testResults.' + this.testService.getColorByDiscLetter(item.name));
      return { name, value: item.value };
    });
    this.answersMost = answers.most.map(item => {
      const name = this.t.instant('testResults.' + this.testService.getColorByDiscLetter(item.name));
      return { name, value: item.value };
    });

    this.mappedAnswers = [
      { value: this.answersMost[0].value, name: 'D' },
      { value: this.answersMost[1].value, name: 'I' },
      { value: this.answersMost[2].value, name: 'S' },
      { value: this.answersMost[3].value, name: 'C' },
    ];
    this.getPersonalityType();
  }

  setView() {
    const currentWidth = document.getElementsByClassName('box')[0].getBoundingClientRect().width;
    this.view = [currentWidth, 400];
  }

  private getPersonalityType() {
    const aboveFifty = this.mappedAnswers.filter(a => a.value >= 50);
    const aboveFiftyAndBelowFiftyFive = this.mappedAnswers.filter(a => a.value >= 50 && a.value <= 55);

    if (aboveFiftyAndBelowFiftyFive.length === 4) {
      // Personality type: DISC
      this.personalityType = ['D', 'I', 'S', 'C'];
    } else if (aboveFifty.length === 0) {
      // Personality type: KODO
      const type = this.t.instant('testResults.specialType');
      this.personalityType = [type];
    } else if (aboveFifty.length === 1) {
      // Personality type: D / I / S / C
      this.personalityType = [aboveFifty[0].name];
    } else {
      // Personality type: DOUBLE
      const ordered = aboveFifty.sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
      this.personalityType = [ordered[0].name, ordered[1].name];
    }

    this.setMonsters();
  }

  private setMonsters(): void {
    if (this.personalityType.length === 4) {
      this.monsters.push({ url: `../../assets/monsters/all-happy.png`, class: 'height-100' });
    } else if (this.personalityType.length === 2) {
      const firstColor = this.testService.getColorByDiscLetter(this.personalityType[0]);
      const secondColor = this.testService.getColorByDiscLetter(this.personalityType[1]);
      const isFirstMonsterBigger = this.isFirstMonsterBigger();

      this.monsters = [
        {
          url: `../../assets/monsters/${ firstColor }-happy.png`,
          class: isFirstMonsterBigger ? 'height-100' : 'height-90',
        },
        {
          url: `../../assets/monsters/${ secondColor }-happy.png`,
          class: isFirstMonsterBigger ? 'height-90' : 'height-100',
        },
      ];
    } else {
      const color = this.testService.getColorByDiscLetter(this.personalityType[0]);
      this.monsters.push({ url: `../../assets/monsters/${ color }-happy.png`, class: 'height-100' });
    }
  }

  onDownloadPdf(): void {
    this.downloadPdfLoading = true;
    this.testService.downloadPdf(this.personalityType).subscribe(() => {
        this.downloadPdfLoading = false;
        this.notificationService.notify('testResults.pdfIsGenerating', 'success');
      },
      err => console.log(err));
  }

  onSendPdf(): void {
    const mappedLeastAnswers = [
      { value: this.answersLeast[0].value, name: 'D' },
      { value: this.answersLeast[1].value, name: 'I' },
      { value: this.answersLeast[2].value, name: 'S' },
      { value: this.answersLeast[3].value, name: 'C' },
    ];

    this.dialog
      .open(InputDialogComponent, {
        width: '90%',
        maxWidth: '500px',
        maxHeight: '90%',
        data: {
          title: '',
          inputType: 'email',
          message: 'testResults.emailInput',
          buttonText: 'testResults.send',
          cancelText: 'testResults.cancel',
        },
      })
      .afterClosed()
      .pipe(filter(email => !!email))
      .subscribe(email => {
        this.sendPdfLoading = true;
        this.testService.sendPdf(this.mappedAnswers, mappedLeastAnswers, this.personalityType, email).subscribe(() => {
          this.sendPdfLoading = false;
          this.notificationService.notify('testResults.mailSentMessage', 'success');
        });
      });
  }

  private isFirstMonsterBigger(): boolean {
    if (this.personalityType[0] === 'C') {
      return true;
    }
    if (this.personalityType[1] === 'C') {
      return false;
    }
    if (this.personalityType[0] === 'S') {
      return true;
    }
    if (this.personalityType[1] === 'S') {
      return false;
    }
    if (this.personalityType[0] === 'D') {
      return true;
    }
    if (this.personalityType[1] === 'D') {
      return false;
    }
    if (this.personalityType[0] === 'I') {
      return true;
    }
    if (this.personalityType[1] === 'I') {
      return false;
    }
  }
}
