<div>
  <div class="row">
    <div class="col-xs-12">
      <div class="list-group-item clearfix instructions-box">
        <h3>{{ 'test.title' | translate }}</h3>
        <h4>{{ 'test.instructions' | translate }}:</h4>
        1. {{ 'test.step1' | translate }}
        <br>
        2. {{ 'test.step2' | translate }}
        <br>
        3. {{ 'test.step3' | translate }}
      </div>
      <div class="list-group-item clearfix instructions-box description-box">
        <h4>{{ 'test.description' | translate }}</h4>
      </div>
    </div>
  </div>
  <app-test-box
    *ngFor="let question of questions; let i = index"
    [question]="question"
    [index]="i"
  ></app-test-box>
  <div class="row">
    <div
      class="row"
      *ngIf="isError">
      <span>{{ 'test.error' | translate }}</span>
    </div>
    <div class="row button-container">
      <button
        type="button"
        class="btn btn-koodoo"
        [disabled]="!this.testService.isAnswerValid()"
        (click)="onSubmit()">
        {{ 'test.button' | translate }}
      </button>
    </div>
  </div>
</div>
