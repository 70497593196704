<div class="content">
  <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse imperdiet justo congue,
    venenatis felis sed, pharetra ante. Nulla vulputate, velit ultricies pretium aliquet, urna enim sagittis tortor, sit
    amet condimentum est mauris et dui. Vestibulum imperdiet rhoncus tortor, in semper neque varius non. Maecenas
    scelerisque a lectus vel tristique. Pellentesque sapien lorem, dictum ut facilisis ac, congue id dolor. Donec
    convallis, diam at eleifend vulputate, nibh est scelerisque massa, quis pharetra mauris ligula in libero. Duis ut
    est sed eros vulputate sagittis in quis massa. Aenean lacinia nec libero in tempus. Phasellus id justo eget ex
    vestibulum tempor eget nec elit. Sed ac convallis lorem. Nulla vulputate elit eget ultricies pharetra. Donec semper,
    tortor quis blandit consectetur, nisl nisl finibus mi, sed luctus nisi magna luctus nibh. Quisque nec condimentum
    lorem, sit amet semper tellus.
  </div>
</div>
