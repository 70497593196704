import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { appRoutes } from './app.routes';
import { HomeComponent } from './containers/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderSimpleComponent } from './components/header-simple/header-simple.component';
import { PageNotFoundComponent } from './containers/errors/404/404.component';
import { DiscTestComponent } from './containers/disc-test/disc-test.component';
import { TestBoxComponent } from './components/test-box/test-box.component';
import { TestRowComponent } from './components/test-box/test-row/test-row.component';
import { TestResultsWithCodeComponent } from './containers/test-results-with-code/test-results-with-code.component';
import { RodoComponent } from './containers/rodo/rodo.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DotsComponent } from './shared/dots/dots.component';
import { CodeCountComponent } from './containers/code-count/code-count.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { InputDialogComponent } from './shared/input-dialog/input-dialog.component';
import { NotificationComponent } from './components/notification/notification.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { InputComponent } from './shared/input/input.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderSimpleComponent,
    PageNotFoundComponent,
    DiscTestComponent,
    TestBoxComponent,
    TestRowComponent,
    TestResultsWithCodeComponent,
    RodoComponent,
    DotsComponent,
    CodeCountComponent,
    LoadingSpinnerComponent,
    InputDialogComponent,
    NotificationComponent,
    InputComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    NgxChartsModule,
    MatIconModule,
    MatDialogModule
  ],
  bootstrap: [AppComponent],
  exports: [
  ],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
