<div class="form-group mb-0">
  <label class="input__label d-inline-flex align-center" *ngIf="label" [class.font-body--bold]="boldLabel">
    {{ label | translate }} <span class="input__field__required ml-1" *ngIf="required">*</span>
  </label>
  <input
    [type]="type"
    name="input"
    class="form-control"
    [class.input__error]="isInvalid"
    [placeholder]="placeholder | translate"
    [formControl]="control"
    [attr.disabled]="disabled ? '' : null"
  />
</div>

<ng-container *ngIf="error">
  <small class="input__error--small d-block">{{ error | translate }}</small>
  <br/>
</ng-container>

<ng-container *ngIf="control.touched">
  <ng-container *ngIf="control.getError('required')">
    <small class="input__error--small d-block">{{ 'forms.required_field' | translate }}</small>
  </ng-container>

  <ng-container *ngIf="control.getError('email')">
    <small class="input__error--small d-block">{{ 'forms.invalid_email_address' | translate }}</small>
  </ng-container>
</ng-container>
