<div class="dialog-container">
  <h2 class="title">{{ data.title | translate }}</h2>
  <button
    class="notranslate close-button"
    aria-label="close dialog"
    tabindex="0"
    (click)="close()">
    <mat-icon>close</mat-icon>
  </button>

  <div mat-dialog-content>
    <form>
      <app-input [type]="data.inputType" [label]="data.message" [control]="$any(input)" [required]="true"></app-input>
    </form>
  </div>
  <div class="dialog-buttons" mat-dialog-actions>
    <button class="btn btn-info mr-20" (click)="close()">{{ data.cancelText | translate }}</button>
    <button class="btn btn-primary" [disabled]="!form.valid" (click)="onSave()">
      {{ data.buttonText | translate }}
    </button>
  </div>
</div>
