<div class="alert-wrapper" *ngIf="text" (close)="text = ''">
  <div [class]="'alert' + ' alert-' + status">
    <ng-container [ngSwitch]="status">
      <mat-icon class="notranslate" *ngSwitchCase="'danger'">error</mat-icon>
      <mat-icon class="notranslate" *ngSwitchCase="'warning'">warning</mat-icon>
      <mat-icon class="notranslate" *ngSwitchCase="'info'">chat</mat-icon>
      <mat-icon class="notranslate" *ngSwitchCase="'success'">done</mat-icon>
    </ng-container>
    <span>{{ text | translate }}</span>
    <div class="alert-close">
      <mat-icon class="notranslate close-icon" (click)="text = ''">close</mat-icon>
    </div>
  </div>
</div>
