import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notification = new ReplaySubject<{ text: string; status: string; delay: number }>();
  notification$ = this.notification.asObservable();

  notify(text: string, status: 'success' | 'warning' | 'danger' | 'info' = 'success', delay: number = 5000): void {
    this.notification.next({ text: text, status: status, delay: delay });
  }
}
