import { Component, Input } from '@angular/core';
import { TestQuestion, TestAnswer } from '../../containers/disc-test/disc-test.model';
import { TestService } from '../../services/test.service';

@Component({
  selector: 'app-test-box',
  templateUrl: './test-box.component.html',
  styleUrls: ['./test-box.component.css']
})
export class TestBoxComponent {
  @Input() question: TestQuestion;
  @Input() index: number;
  points = [0, 0];

  constructor(private testService: TestService) {
  }

  onRowClick(event: { index: number, isMost: boolean, answer: TestAnswer }): void {
    if (event.isMost) {
      this.selectAnswer(0, event.index, event.answer);
    } else {
      this.selectAnswer(1, event.index, event.answer);
    }
    this.saveAnswer();
  }

  private selectAnswer(count: number, index: number, answer: TestAnswer): void {
    this.points[count] = answer.points[count];
    this.question.answers.forEach(a => {
      a.isSelected[count] = false;
    });
    this.question.answers[index].isSelected[count] = true;

    const opposite = count === 0 ? 1 : 0;
    if (this.question.answers[index].isSelected[opposite] === true) {
      this.points[opposite] = 0;
      this.question.answers[index].isSelected[opposite] = false;
    }
  }

  private saveAnswer() {
    if (this.points[0] > 0 && this.points[1] > 0) {
      this.testService.setAnswers(this.index, [this.points[0], this.points[1]]);
      this.question.isSelected = true;
    } else {
      this.testService.setAnswers(this.index, [0, 0]);
      this.question.isSelected = false;
    }
  }
}
