import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const CODE_API = '/api/code';
const GIFT_CODE = 'gift-code';
const EXPIRES_AT = 'expires_at';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  constructor(private http: HttpClient) {}

  isCodeValid(code: string): Observable<boolean> {
    return this.http.get<boolean>(`${CODE_API}/${code}`, httpOptions);
  }

  getCodeCount(code: string): Observable<number | null> {
    return this.http.get<number | null>(`${CODE_API}/${code}/count`, httpOptions);
  }

  public saveCode(code: string): void {
    localStorage.setItem(GIFT_CODE, code);
  }

  public getCode(): string | null {
    return localStorage.getItem(GIFT_CODE);
  }

  public removeCode(): void {
    localStorage.removeItem(GIFT_CODE);
    localStorage.removeItem(EXPIRES_AT);
  }
}
