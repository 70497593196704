import { Injectable } from '@angular/core';
import { TestQuestion } from '../containers/disc-test/disc-test.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { questions } from './test.config';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { CodeService } from './code.service';

const TEST_API = '/api/test';

export interface AnswersResponse {
  most: { name: string, value: number }[],
  least: { name: string, value: number }[],
}

@Injectable({
  providedIn: 'root',
})
export class TestService {
  private selectedAnswers = new Array<{ index: number, points: number[] }>();

  private questions: TestQuestion[] = questions;

  constructor(private http: HttpClient, private codeService: CodeService) {
  }

  countAnswers(): Observable<AnswersResponse> {
    return this.http.post<AnswersResponse>(`${ TEST_API }/count`, { answers: this.selectedAnswers.slice(), code: this.codeService.getCode() });
  }

  downloadPdf(personalityType: string[]) {
    const type = personalityType.join().replace(',', '');
    return this.http.get(`${ TEST_API }/pdf/download/${ type }`, { responseType : 'blob' })
      .pipe(tap(response => {  saveAs(response, `koodoo_profil_DISC_${ type }.pdf`) }));
  }

  sendPdf(answersMost: { name: string, value: number }[], answersLeast: { name: string, value: number }[], personalityType: string[], email: string): Observable<null> {
    return this.http.post<null>(`${ TEST_API }/pdf/send`, { answersMost, answersLeast, personalityType, email });
  }

  getTestQuestions(): TestQuestion[] {
    return this.questions.slice();
  }

  setAnswers(index: number, points: number[]): void {
    this.selectedAnswers[index] = { index, points: [points[0], points[1]] };
  }

  isAnswerValid(): boolean {
    if (this.selectedAnswers.includes(undefined)
      || this.selectedAnswers.length !== this.questions.length) {
      return false;
    }
    return !this.selectedAnswers.some(a => a.points[1] === 0 || a.points[0] === 0);
  }

  public saveAnswers(answers: AnswersResponse): void {
    localStorage.setItem('answers', JSON.stringify(answers));
  }

  public getAnswers(): string | null {
    return localStorage.getItem('answers');
  }

  getColorByDiscLetter(letter: string): string {
    if (letter.toUpperCase() === 'D') {
      return 'red';
    }
    if (letter.toUpperCase() === 'I') {
      return 'yellow';
    }
    if (letter.toUpperCase() === 'S') {
      return 'green';
    }
    if (letter.toUpperCase() === 'C') {
      return 'blue';
    }
  }
}
