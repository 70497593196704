import { Routes } from '@angular/router';
import { HomeComponent } from './containers/home/home.component';
import { RodoComponent } from './containers/rodo/rodo.component';
import { PageNotFoundComponent } from './containers/errors/404/404.component';
import { DiscTestComponent } from './containers/disc-test/disc-test.component';
import { TestResultsWithCodeComponent } from './containers/test-results-with-code/test-results-with-code.component';
import { CodeCountComponent } from './containers/code-count/code-count.component';

export const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'rodo', component: RodoComponent },
  { path: 'test', component: DiscTestComponent },
  { path: 'test/wyniki', component: TestResultsWithCodeComponent },
  { path: 'code/:code/count', component: CodeCountComponent },
  { path: '**', component: PageNotFoundComponent },

  // { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  // { path: 'profile', component: ProfileComponent },
];
