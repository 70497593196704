<div class="single-row">
  <div class="buttons-row">
    <img
      [src]="answer.isSelected[0] ? '../../../assets/icon-yes-selected.png' : '../../../assets/icon-yes.png'"
      alt="Icon most"
      (click)="onSelect(true)"/>
    <img
      [src]="answer.isSelected[1] ? '../../../assets/icon-no-selected.png' : '../../../assets/icon-no.png'"
      alt="Icon least"
      (click)="onSelect(false)"/>
  </div>
  <div></div>
  <span>{{ answer.answer | translate }}</span>
</div>
