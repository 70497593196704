import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnDestroy, OnInit {
  text: string;
  status: string;

  private readonly destroy$ = new Subject<boolean>();

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.notification$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.status = data.status || 'success';
      this.text = data.text;
      this.closeAfterDelay(data.delay);
    });
  }

  closeAfterDelay(delay) {
    timer(delay).subscribe(() => this.text = '');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
