import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() label: string;
  @Input() boldLabel = false;
  @Input() placeholder = '';
  @Input() error: string = null;
  @Input() control: FormControl;
  @Input() disabled = false;
  @Input() required = false;
  @Input() type = 'text';
  @Input() valid = true;

  get isInvalid(): boolean {
    return !this.valid || (!this.control.valid && this.control.touched);
  }
}
