import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface InputDialogData {
  inputType: 'text' | 'email';
  title: string;
  message: string;
  buttonText: string;
  cancelText: string;
}

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements OnInit {
  readonly form: FormGroup;
  data: InputDialogData

  get input(): AbstractControl {
    return this.form.get('input');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: InputDialogData,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InputDialogComponent>,
  ) {
    this.data = data;
    const validators = [Validators.required, data.inputType === 'email' ? Validators.email : null];
    this.form = this.fb.group({
      input: this.fb.control('', validators),
    });
  }

  ngOnInit(): void {
  }

  close(input: string = null) {
    this.dialogRef.close(input);
  }

  onSave() {
    if (this.form.invalid) {
      return;
    }

    this.close(this.input.value);
  }
}
