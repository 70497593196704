import { Component, OnInit } from '@angular/core';
import { CodeService } from '../../services/code.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  error: string = null;

  readonly form: FormGroup;

  get code(): AbstractControl {
    return this.form.get('code');
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly codeService: CodeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute) {

    this.form = this.fb.group({
      code: this.fb.control('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      if (code === 'missing_code') {
        this.error = 'gift.missingCode';
        history.replaceState({}, '', '/');
      } else if (code === 'wrong_code') {
        this.error = 'gift.wrongCode';
        history.replaceState({}, '', '/');
      } else if (code === 'answers_missing') {
        this.error = 'gift.answersMissing';
        history.replaceState({}, '', '/');
      }
    });
  }

  onButtonClick(): void {
    this.codeService
      .isCodeValid(this.code.value)
      .subscribe(isCodeValid => {
        if (isCodeValid) {
          this.codeService.saveCode(this.code.value);
          this.router.navigate(['test']).then();
        }
        this.code.patchValue(null);
        this.error = 'gift.wrongCode';
      });
  }
}
