import { Component } from '@angular/core';

@Component({
  selector: 'app-rodo',
  templateUrl: './rodo.component.html',
  styleUrls: ['./rodo.component.scss']
})
export class RodoComponent{
  constructor() {
  }
}
