<app-notification class="notification"></app-notification>
<div class="body">
  <app-header-simple></app-header-simple>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>
