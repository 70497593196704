import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { CodeService } from '../../services/code.service';

@Component({
  selector: 'app-code-count',
  templateUrl: './code-count.component.html',
  styleUrls: ['./code-count.component.scss']
})
export class CodeCountComponent {
  code: string = null;
  codeCount: number = 0;
  error = false;

  constructor(private readonly codeService: CodeService, private readonly route: ActivatedRoute) {
    this.route.params
      .pipe(
        take(1),
        map((params: Params) => params.code),
        tap(code => this.code = code),
        switchMap((code: string) => this.codeService.getCodeCount(code)),
        tap(codeCount => {
          if (codeCount || codeCount === 0) {
            this.codeCount = codeCount;
          } else {
            this.error = true;
          }
        }),
        shareReplay(1),
      )
      .subscribe();
  }
}
