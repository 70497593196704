<div class="home">
  <form>
    <app-input [type]="'text'" [error]="error" [label]="'gift.enterCode'"
               [control]="$any(code)" [required]="true"></app-input>
    <div class="button--line">
      <button
        class="btn btn-primary"
        (click)="onButtonClick()"
        [disabled]="!form.valid">
        {{ 'gift.send' | translate }}
      </button>
    </div>
  </form>
</div>
