<div class="test">
  <div>
    <div class="title">
      <h3>Styl dopasowany</h3>
    </div>
    <div class="row">
      <ngx-charts-bar-vertical
        class="chart"
        (window:resize)="onResize()"
        [view]="view"
        [title]="'Styl dopasowany'"
        [scheme]="colorScheme"
        [results]="answersMost"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [xAxisLabel]=""
        [yAxisLabel]=""
        [yScaleMax]="100"
        [legendTitle]="'Legenda'"
        [showDataLabel]="true"
        style="fill: #ececec;"
      >
      </ngx-charts-bar-vertical>
    </div>

    <div class="title title-basic">
      <h3>Styl naturalny</h3>
    </div>
    <div class="row">
      <ngx-charts-bar-vertical
        (window:resize)="onResize()"
        [view]="view"
        [title]="'Styl naturalny'"
        [scheme]="colorScheme"
        [results]="answersLeast"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [xAxisLabel]=""
        [yAxisLabel]=""
        [yScaleMax]="100"
        [legend]="true"
        [legendTitle]="'Legenda'"
        [showDataLabel]="true"
        dataLabelFormatting=""
        style="fill: #ececec;">
      </ngx-charts-bar-vertical>
    </div>
    <div class="content">
      <div class="text">
        <div class="">{{ 'testResults.yourPersonalityType' | translate }}</div>
        <div class="personality-type">
          <ng-container *ngFor="let type of personalityType; let last=last">
            <span [ngClass]="'type-' + type.toLowerCase()">{{ type }}</span>
          </ng-container>
        </div>
      </div>
      <img
        *ngIf="monsters.length === 1"
        class="item monsters"
        [ngClass]="monsters[0].class ? monsters[0].class : ''"
        [src]="monsters[0].url"
        alt="Happy Koodoo monsters">
      <ng-container *ngIf="monsters.length >1">
        <div class="two-monsters">
          <img
            *ngFor="let monster of monsters"
            class="item monsters"
            [ngClass]="monster.class ? monster.class : ''"
            [src]="monster.url"
            alt="Happy Koodoo monsters">
        </div>
      </ng-container>
    </div>

    <div class="box">
      <button
        type="button"
        class="btn btn-koodoo"
        [disabled]="downloadPdfLoading"
        (click)="onDownloadPdf()">
        <ng-container *ngIf="!downloadPdfLoading">{{ 'testResults.downloadPdf' | translate }}</ng-container>
        <app-loading-spinner *ngIf="downloadPdfLoading"></app-loading-spinner>
      </button>
      <button
        type="button"
        class="btn btn-koodoo"
        [disabled]="sendPdfLoading"
        (click)="onSendPdf()">
        <ng-container *ngIf="!sendPdfLoading">{{ 'testResults.sendPdf' | translate }}</ng-container>
        <app-loading-spinner *ngIf="sendPdfLoading"></app-loading-spinner>
      </button>
    </div>
  </div>
</div>
