import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CodeService } from './services/code.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  currentLang = '';

  constructor(
    private readonly translateService: TranslateService,
    private readonly codeService: CodeService) {
  }

  ngOnInit() {
    this.translateService.setDefaultLang('pl');
  }

  ngOnDestroy() {
    this.codeService.removeCode();
  }
}
